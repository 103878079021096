import introLecture from './lecture_notes/stat547u_lecture01.pdf';
import rkhsLecture from './lecture_notes/stat547u_lecture02.pdf';
import doubleDescentLecture from './lecture_notes/stat547u_lecture03.pdf';
import rmtLecture from './lecture_notes/stat547u_lecture04.pdf';
import implicitRegLecture from './lecture_notes/stat547u_lecture05.pdf';
import benignOverfitLecture from './lecture_notes/stat547u_lecture06.pdf';
import linApproxLecture from './lecture_notes/stat547u_lecture07.pdf';
import ntkInitLecture from './lecture_notes/stat547u_lecture08.pdf';
import ntkOptLecture from './lecture_notes/stat547u_lecture09.pdf';
import classificationLecture from './lecture_notes/stat547u_lecture10.pdf';
import featureLearningLecture from './lecture_notes/stat547u_lecture11.pdf';

export default {
  "introLecture": introLecture,
  "rkhsLecture": rkhsLecture,
  "doubleDescentLecture": doubleDescentLecture,
  "rmtLecture": rmtLecture,
  "implicitRegLecture": implicitRegLecture,
  "benignOverfitLecture": benignOverfitLecture,
  "linApproxLecture": linApproxLecture,
  "ntkInitLecture": ntkInitLecture,
  "ntkOptLecture": ntkOptLecture,
  "classificationLecture": classificationLecture,
  "featureLearningLecture": featureLearningLecture,
};
